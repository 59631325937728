import React, { useState } from 'react';
import { Helmet } from 'react-helmet'
import ListingForm from '../components/ListingForm';
import Listings from '../components/Listings';
import Pagination from '../components/Pagination';
import Wa from '../assets/images/wa.png';


const Home = () => { 
  const [listings, setListings] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [listingsPerPage, setListingsPerPage] = useState(3)
  const [active, setActive] = useState(1)
  

  const indexOfLastListings = currentPage * listingsPerPage
  const indexOfFirstListings = indexOfLastListings - listingsPerPage
  const currentListings = listings.slice(indexOfFirstListings, indexOfLastListings)

  const visitPage = (page) => {
    setCurrentPage(page)
    setActive(page)
  }

  const previous_number = () => {
    if (currentPage !==1){
      setCurrentPage(currentPage-1)
      setActive(currentPage-1)
    }
  }

  const next_number = () => {
    if (currentPage !== Math.ceil(listings.length/3)){
      setCurrentPage(currentPage+1)
      setActive(currentPage+1)
    }
  }


  return (
      <main className='home'>
            <Helmet>
                <title>PIK2 - Home</title>
                <meta
                    name='description'
                    content='PIK2 Home Page'
                />
		
            </Helmet>
            
            

            <section className='home__form'>
                <ListingForm setListings={setListings} />
            </section>
            <section className='home__listings'>
                <Listings listings={currentListings} />
            </section>
            <section className='home__pagination'>
            <section  className='row'>
                <div>
                  <h3>
                  Langkah2 : <br></br>
                  1 Pilih Jual or Sewa <br></br>
                  2 Pilih Minimum Price, bisa pilih Rp0+ <br></br>
                  3 Pilih Beedrooms, bisa pilih 0+ <br></br>
                  4 Pilih Tipe Property yang di inginkan <br></br>
                  5 Pilih Baths, bisa pilih 0+<br></br>
                  6 Pilih Luas Tanah, bisa 20+<br></br>
                  7 Pilih Luas Bangunan, bisa 20+<br></br>
                  8 Pilih Has Photos, bisa 1+<br></br>
                  9 Pilih Keyword bisa di kosongkan, atau nama property yang anda cari<br></br>
                  10 Untuk lebih Jelas bisa klik tombol whatsApp (WA) disamping kanan bawah<br></br>
                  11 Semua Harga dan unit bisa berubah2 tanpa pemberitahuan terlebih dahulu untuk lebih update bisa klik tombol whatsApp (WA) disamping kanan bawah<br></br>
                  </h3>
                </div>
                </section>
                <div className='row'>
                    {
                        listings.length !== 0 ? (
                            <Pagination
                                itemsPerPage={listingsPerPage}
                                count={listings.length}
                                visitPage={visitPage}
                                previous={previous_number}
                                next={next_number}
                                active={active}
                                setActive={setActive}
                            />
                        ) : null
                    }
                </div>
                
            </section>

            {/* <a href={`https://api.whatsapp.com/send?phone=6281533183138&text=Hallo%20Saya ingin informasi pik2%20 ${window.location.href}`} target="_blank">
            <button class="btn-floating whatsapp">
              <img  class ="wa" src={Wa} alt="whatsApp" />
                <span>+6281533183138 Aldi</span>
            </button>
          </a> */}
    
        </main>
    )
  
}

export default Home


import React from 'react' 

const notFound = ()=> {
  return (

    <div className="notFound">
    <h1 className="notfound__heading">404 Not Found</h1>
    <p className="notfound__paragraph">The link you requested does not exist on out website.</p>
  </div>
    )

  }

export default notFound
import React, {Fragment} from 'react';
import { Link, NavLink } from 'react-router-dom'
import {connect} from 'react-redux'
import { logout } from '../actions/auth';
import Alert from './Alert'
import PropTypes from 'prop-types'
import Wa from '../assets/images/wa.png';

import ReactGA from "react-ga4";


ReactGA.initialize("G-1WGW1H0HLM");

const navbar = ({ auth: { isAuthenticated, loading }, logout })=> {
  const authLinks = (
    <a className='navar__top__auth__link' onClick={logout} href="#!">Logout</a>
  )
  const guestLinks = (
    <Fragment>
      <Link className='navbar__top__auth__link' to='/login'>Login</Link>
      <Link className='navbar__top__auth__link' to='/signup'>Sign Up</Link>
    </Fragment>
  )

  return (
    <Fragment>
	
      <nav className='navbar'>
        <div className='navbar__top'>
          <div className='navbar__top__logo' >
            <Link className='navbar__top__logo__link' to='/'>PIK2</Link>
          </div>
          <div className='navbar__top__auth'>
            { !loading && (<Fragment>{ isAuthenticated ? authLinks: guestLinks}</Fragment>)}
          </div>
        </div>
        <div className='navbar__bottom'>
          <li className='navbar__bottom__item'>
            <NavLink className='navbar__bottom__item__link' to='/'>Home</NavLink>
          </li>
          <li className='navbar__bottom__item'>
            <NavLink className='navbar__bottom__item__link' to='/listings'>Listings</NavLink>
          </li>
          <li className='navbar__bottom__item'>
            <NavLink className='navbar__bottom__item__link' to='/about'>About</NavLink>
          </li>
          {/* <li className='navbar__bottom__item'>
            <NavLink className='navbar__bottom__item__link'  to = {`https://api.whatsapp.com/send?phone=6281533183138&text=Hallo%20Saya ingin informasi pik2%20${window.location.href}`}    target = '_blank'>WhatsApp</NavLink>
          </li> */}
          <li className='navbar__bottom__item'>
            <NavLink className='navbar__bottom__item__link' to='/contact'>Contact</NavLink>
          </li>
          </div>
          
          <a href={`https://api.whatsapp.com/send?phone=6281533183138&text=Hallo%20Saya ingin informasi pik2%20 ${window.location.href}`} target="_blank">
            <button class="btn-floating whatsapp">
              <img  class ="wa" src={Wa} alt="whatsApp" />
                <span>+6281533183138 Aldi</span>
            </button>
          </a>

      </nav>
      <Alert />
    </Fragment>
  )
}

navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
  }

const mapStateToProps = state => ({
  auth: state.auth  
  })


export default connect(mapStateToProps, { logout })(navbar);

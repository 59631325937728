import React, {useState} from 'react'
import axios from 'axios'
import {Oval} from 'react-loader-spinner'
import PropTypes from 'prop-types'

const ListingForm = (props) => {
  const [formData, setFormData] = useState ({ 
    sale_type: 'Jual',
    price: 'Rp0+',
    bedrooms: '0+', 
    home_type: 'Rumah', 
    bathrooms: '0+',
    sqft: '20+',
    sqft_building: '20+',
    days_listed: 'Any',
    has_photos: '1+',
    open_house: 'false',
    keywords: ''
  })

  const { sale_type, price, bedrooms, home_type, bathrooms, sqft, sqft_building, days_listed, has_photos, open_house, keywords } = formData;

  const [loading, setLoading] = useState(false)
  
  const onChange = e => setFormData({...formData, [e.target.name]: e.target.value})

  const onSubmit = e => {
    e.preventDefault()

    const config ={
      headers: {
        'Content-Type': 'application/json'
      }
    }
    
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_URL}/api/listings/search`, { sale_type, price, bedrooms, home_type, bathrooms, sqft, sqft_building, days_listed, has_photos, open_house, keywords }, config)
    .then(res => {
      setLoading(false)
      props.setListings(res.data)
      window.scrollTo(0,0)
    })
    .catch(err => {
      setLoading(false)
      window.scrollTo(0,0)
    })
    
  }


  return (
    <form className='listingform' onSubmit={e=> onSubmit(e)}>
      <div className='row'>
        <div className='col-1-of-6'>
          <div className='listingform__section'>
            <label className='listingform__label' htmlFor="sale_type">Jual or Sewa</label>
            <select className='listingform__select' name="sale_type" onChange={e=>onChange(e)} value={sale_type} >
              <option>Jual</option>
              <option>Sewa</option>
            </select>
          </div>
          <div className='listingform__section'>
            <label className='listingform__label' htmlFor="sqft">Luas Tanah</label>
            <select className='listingform__select' name="sqft" onChange={e=>onChange(e)} value={sqft} >
              <option>20+</option>
              <option>30+</option>
              <option>40+</option>
              <option>50+</option>
              <option>70+</option>
              <option>100+</option>
              <option>120+</option>
              <option>150+</option>
              <option>170+</option>
              <option>200+</option>
              <option>220+</option>
              <option>250+</option>
              <option>300+</option>
              <option>350+</option>
              <option>400+</option>
              <option>500+</option>
              <option>600+</option>
              <option>700+</option>
              <option>800+</option>
              <option>900+</option>
              <option>1000+</option>
              <option>Any</option>
            </select>
          </div>
          {/* <div className='listingform__section'>
            <label className='listingform__label' htmlFor="sqft_building">Luas Bangunan</label>
            <select className='listingform__select' name="sqft_building" onChange={e=>onChange(e)} value={sqft_building} >
            <option>20+</option>
              <option>30+</option>
              <option>40+</option>
              <option>50+</option>
              <option>70+</option>
              <option>100+</option>
              <option>120+</option>
              <option>150+</option>
              <option>170+</option>
              <option>200+</option>
              <option>220+</option>
              <option>250+</option>
              <option>300+</option>
              <option>350+</option>
              <option>400+</option>
              <option>Any</option>
            </select>
          </div> */}
        </div>
        <div className='col-1-of-6'>
          <div className='listingform__section'>
            <label className='listingform__label' htmlFor="price">Minimum Price</label>
            <select className='listingform__select' name="price" onChange={e=>onChange(e)} value={price} >
              <option>Rp0+</option>
              <option>Rp250Juta+</option>
              <option>Rp500Juta+</option>
              <option>Rp1M+</option>
              <option>Rp2M+</option>
              <option>Rp3M+</option>
              <option>Rp4M+</option>
              <option>Rp5M+</option>
              <option>Rp6M+</option>
              <option>Rp8M+</option>
              <option>Rp10M+</option>
              <option>Rp12M+</option>              
              <option>Rp15M+</option>              
              <option>Rp17M+</option>              
              <option>Rp20M+</option>              
              <option>Rp25M+</option>              
              <option>Rp30M+</option>              
              <option>Rp40M+</option>              
              <option>Rp50M+</option>              
              <option>Any</option>
            </select>
          </div>
          {/* <div className='listingform__section'>
            <label className='listingform__label' htmlFor="days_listed">Days Listed</label>
            <select className='listingform__select' name="days_listed" onChange={e=>onChange(e)} value={days_listed} >
              <option>Any</option>
              <option>1 of less</option>
              <option>2 of less</option>
              <option>5 of less</option>
              <option>10 of less</option>
              <option>20 of less</option>
            </select>
          </div> */}
          <div className='listingform__section'>
            <label className='listingform__label' htmlFor="sqft_building">Luas Bangunan</label>
            <select className='listingform__select' name="sqft_building" onChange={e=>onChange(e)} value={sqft_building} >
            <option>20+</option>
              <option>30+</option>
              <option>40+</option>
              <option>50+</option>
              <option>70+</option>
              <option>100+</option>
              <option>120+</option>
              <option>150+</option>
              <option>170+</option>
              <option>200+</option>
              <option>220+</option>
              <option>250+</option>
              <option>300+</option>
              <option>350+</option>
              <option>400+</option>
              <option>Any</option>
            </select>
          </div>
        </div>
        <div className='col-1-of-6'>
          <div className='listingform__section'>
            <label className='listingform__label' htmlFor="bedrooms">Bedrooms</label>
            <select className='listingform__select' name="bedrooms" onChange={e=>onChange(e)} value={bedrooms} >
              <option>0+</option>
              <option>1+</option>
              <option>2+</option>
              <option>3+</option>
              <option>4+</option>
              <option>5+</option>
              <option>6+</option>
              <option>7+</option>
            </select>
          </div>
          <div className='listingform__section'>
            <label className='listingform__label' htmlFor='has_photos'>Has Photos</label>
            <select className='listingform__select' name='has_photos' onChange={e => onChange(e)} value={has_photos}>
                <option>1+</option>
                <option>3+</option>
                <option>5+</option>
                <option>10+</option>
                <option>15+</option>
            </select>
          </div>
        </div>
        <div className='col-1-of-6'>
          <div className='listingform__section'>
              <label className='listingform__label' htmlFor='home_type'>Tipe Property</label>
              <select className='listingform__select' name='home_type' onChange={e => onChange(e)} value={home_type}>
                  <option>Rumah</option>
                  <option>Apartment</option>
                  <option>Rukan</option>
                  <option>Kavling</option>
                  <option>Soho</option>
                  <option>Gudang</option>
                  <option>Office</option>
                  <option>Condotel</option>
              </select>
          </div>
          <div className='listingform__section'>
              <label className='listingform__label' htmlFor='keywords'>Keywords</label>
              <input className='listingform__input' name='keywords' type='text' onChange={e => onChange(e)} value={keywords} />
          </div>
        </div>
        <div className='col-1-of-6'>
          <div className='listingform__section'>
              <label className='listingform__label' htmlFor='bathrooms'>Baths</label>
              <select className='listingform__select' name='bathrooms' onChange={e => onChange(e)} value={bathrooms}>
                  <option>0+</option>
                  <option>1+</option>
                  <option>2+</option>
                  <option>3+</option>
                  <option>4+</option>
                  <option>5+</option>
              </select>
          </div>
          {/* <div className='listingform__altsection'>
              <label className='listingform__label' htmlFor='open_house'>Open Houses</label>
              <input className='listingform__checkbox' name='open_house' type='checkbox' onChange={e => onChange(e)} value={open_house} />
          </div> */}
        </div>
        <div className='col-1-of-6'>
          {loading ?
              <div className='listingform__loader'>
                  <Oval
                      type="Oval"
                      color="#424242"
                      height={50}  
                      width={50}
                  /> 
              </div> : 
              <button className='listingform__button listingform__button--primary'>Save</button>
          }
        </div>
        
      </div>
    </form>
  )
}

ListingForm.propTypes = {
  setListings: PropTypes.func.isRequired
};

export default ListingForm
import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './containers/Home'
import About from './containers/About'
import Contact from './containers/Contact'
import Listings from './containers/Listings'
import ListingDetail from './containers/ListingDetail'
import Login from './containers/Login'
import SignUp from './containers/SignUp'
import NotFound from './components/NotFound';
import Layout from './hocs/Layout'
import PrivateRoute from './components/PrivateRoute';

import { Provider } from 'react-redux';
import store from './store';

import './sass/main.scss';

const App = () => (
  <Provider store={store}>
    <Router>
      <Layout>
        <Routes>
          <Route exact path='/' element={<Home/>} />
          <Route exact path='/about' element={<About/>} />
          <Route exact path='/contact' element={<Contact/>} />
          <Route exact path='/listings' element={<Listings/>} />
          {/* <Route exact path='/listings/:id' element={<ListingDetail/>} /> */}
          {/* <Route exact path='/listings/:slug' element={<PrivateRoute component={<ListingDetail />}/>} /> */}
          {/* <Route exact path='/listings/:id' element={<PrivateRoute><ListingDetail/></PrivateRoute> } /> */}
          {/* <PrivateRoute exact path='/listings/:id' Component={ListingDetail} /> */}
          <Route element={<PrivateRoute />}>
            <Route
              path='/listings/:slug'
              element= {<ListingDetail />}
            />
          </Route>
          <Route exact path='/login' element={<Login/>} />
          <Route exact path='/signUp' element={<SignUp/>} />
          <Route path='*' element={<NotFound/>} />
        </Routes>
      </Layout>
    </Router>
  </Provider>

)
export default App;

// import React, { Component } from 'react';
// import { Route, Navigate, Outlet} from 'react-router-dom';
// import {connect} from 'react-redux';
// import PropTypes from 'prop-types';

  
// const PrivateRoute = ({ component: Component, auth: { isAuthenticated, loading }, ...rest }) => (
//       <Route
  
//       {...rest}
//       render={props => !isAuthenticated && !loading ? (<Navigate to='/login' />) : (<Component {...props} />)}
//       />
//   );
  


// PrivateRoute.propTypes = {
//     auth: PropTypes.object.isRequired
// };

// const mapStateToProps = state => ({
//     auth: state.auth
// });

// export default connect(mapStateToProps)(PrivateRoute);

// const PrivateRoute = ({ isAuthenticated }) => {
//   if (isAuthenticated === null) {
//     return null;
//   }
//   return isAuthenticated ? <Outlet /> : <Navigate to='/login' replace />;
// };

// const mapStateToProps = state => ({
//   isAuthenticated: state => state.auth.isAuthenticated,
// });

// export default connect(mapStateToProps)(PrivateRoute);

// import React from 'react';
// import { connect } from 'react-redux';
// import { Outlet, Navigate} from 'react-router-dom';


// const PrivateRoute = ({  isAuthenticated}) => {
  
//   if (isAuthenticated === null) {
//     return null;
//   }
//   return isAuthenticated ?   <Outlet />: <Navigate to='/login' replace />;

// };



// const mapStateToProps = state => ({
//   isAuthenticated: state => state.auth.isAuthenticated,
// });




// export default connect(mapStateToProps)(PrivateRoute);

import React from 'react';
import { connect } from 'react-redux';
import { Outlet, Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ auth: {isAuthenticated, loading }, ...rest  }) => {
  // return (!isAuthenticated && !loading) ? (<Navigate to='/login' />) : (<Outlet {...rest}/>)}
  return (!isAuthenticated && !loading) ?  (<Outlet {...rest}/>) : (<Outlet {...rest}/>)}
  
PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired};

const mapStateToProps = state => ({
  auth: state.auth});

export default connect(mapStateToProps)(PrivateRoute);
